<template lang="pug">
  .app-select-search-and-tips
    form.app-select-search-wrapper(@submit.prevent="handleEnterPressed")
      BInputGroup
        template(v-if="withSearchIcon" v-slot:prepend)
          BInputGroupText.search-icon
            FaIcon(icon="search")
        BFormInput.app-select-search(
          v-if="opened"
          :autofocus="autofocus"
          :value="value"
          :placeholder="searchPlaceholder"
          autocomplete="off"
          tabindex="0"
          type="search"
          @keyup.esc.stop.prevent
          @input="handleInput"
        )

    BDropdownText.app-select-tips(v-if="tips") {{ tips }}
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: ""
      },

      autofocus: {
        type: Boolean,
        default: true
      },

      opened: {
        type: Boolean,
        default: false
      },

      searchPlaceholder: {
        type: String,
        default() {
          return this.$t("components.select.search")
        }
      },

      tips: String,

      withSearchIcon: {
        type: Boolean,
        default: false
      }
    },

    methods: {
      handleInput(value) {
        this.$emit("input", value)
      },

      handleEnterPressed(event) {
        event.stopPropagation()
        event.preventDefault()

        this.$emit("enter-pressed")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .app-select-search-and-tips
    +flex-column-gapped

    .search-icon
      background-color: $default-gray-light
      border: 0
      height: 34px
      padding: 6px 1px 6px 12px

      svg
        color: $default-purple

    .app-select-search
      background: $default-gray-light
      border: 0
      border-radius: 0
      height: 30px
      font-size: 0.8rem

      &:focus
        outline: 0
        box-shadow: none !important

    .app-select-tips
      background: $default-purple-light
      border-radius: 0
      color: $default-gray
      cursor: help
      font-size: 0.8rem

      p
        padding: 5px 7px !important
</style>
